html {
    /* 浏览器默认font-size=16px*/
    /* 16*62.5=10px */
    /* 1rem = 10px */
    font-size: 62.5%;
}

html body #root .App {
    /* viewport height = vh*/
    min-height: 100vh;
}

/*
.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    vertical-align: middle;
}

.rectangular {
    width: 32px;
    height: 32px;
    background: rgba(0, 0, 0, 0.04);
}*/

.iframe-hack {
    width: 100%;
    height: 100vh;
}

/* a tag color */
a:hover,
a:active,
a:visited,
a:link,
a:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    text-decoration: none;
    /*color: #03c1fd;*/
    color: inherit;
    padding: 2px 8px;
}

