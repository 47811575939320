.exception {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 504px;
    border: none;
}
.exception div {
    text-align: center;
}

.exception .exceptionImage {
    margin-bottom: 22px;
}

.exception .statuscode {
    margin-bottom: 6px;
    color: #000;
    font-size: 28px;
    line-height: 52px;
    text-align: center;
}
.exception .description {
    color: #666;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
}
