.myapp-container {
    width: 100%;
    margintop: 20px;
}

.app-item {
    width: 200px;
    height: 200px;
    border: 1px solid #eeeeee;
    padding: 5px 5px;
    box-sizing: border-box;
    margin: 10px 10px;
}

.app-img {
    width: 161px;
    height: 108px;
    /*border-radius: 50%;*/
    vertical-align: middle;
}

a.app-name {
    text-decoration: none;
}

a.app-name:hover,
a.app-name:active,
a.app-name:visited,
a.app-name:link,
a.app-name:focus {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    outline: none;
    background: none;
    text-decoration: none;
    color: #333333;
    padding: 2px 8px;
}
