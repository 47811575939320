@theme-colors: {
    primary: rgb(0,82,204);
    secondary: #95ec69;
    success: #52c41a;
    warning: #faad14;
    error: #f5222d;
    info: #ededed;
    light: #f7f7f7;
    dark: #4c4c4c;
};

each(@theme-colors, {
    .icon-@{key} {
        color: @value;
    }
});
